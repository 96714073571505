const demo_app = {
    title: '你已成功登錄實時預覽！',
    subtitle: '以下是本次登錄的用戶信息：',
    username: '用戶名：',
    user_id: '用戶 ID：',
    sign_out: '退出實時預覽',
    continue_explore: '或繼續探索',
    customize_sign_in_experience: '自定義登錄體驗',
    enable_passwordless: '啟用無密碼登錄',
    add_social_connector: '添加社交連接器',
};
export default demo_app;
