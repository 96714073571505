@use '@/scss/underscore' as _;

.header {
  padding: _.unit(6);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .webhookIcon {
    margin-left: _.unit(2);
    width: 60px;
    height: 60px;
  }

  > *:not(:first-child) {
    margin-left: _.unit(6);
  }

  .metadata {
    flex: 1;

    > div {
      display: flex;
      align-items: center;

      > *:not(:last-child) {
        margin-right: _.unit(2);
      }
    }

    .title {
      font: var(--font-title-1);
      color: var(--color-text);
    }

    .text {
      font: var(--font-label-2);
      color: var(--color-text-secondary);
    }

    .verticalBar {
      @include _.vertical-bar;
      height: 12px;
    }
  }
}

.icon {
  color: var(--color-text-secondary);
}
