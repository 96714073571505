const others = {
    terms_of_use: {
        title: 'WARUNKI',
        terms_of_use: 'URL warunków użytkowania',
        terms_of_use_placeholder: 'https://twoje.warunki.użytkowania/',
        privacy_policy: 'URL polityki prywatności',
        privacy_policy_placeholder: 'https://twoja.polityka.prywatności/',
    },
    languages: {
        title: 'JĘZYKI',
        enable_auto_detect: 'Włącz wykrywanie automatyczne',
        description: 'Twoje oprogramowanie wykrywa ustawienia językowe użytkownika i przełącza się na język lokalny. Możesz dodać nowe języki, tłumacząc interfejs użytkownika z angielskiego na inny język.',
        manage_language: 'Zarządzanie językiem',
        default_language: 'Domyślny język',
        default_language_description_auto: 'Domyślny język będzie używany, gdy wykryty język użytkownika nie będzie objęty w bieżącej bibliotece językowej.',
        default_language_description_fixed: 'Gdy wykrywanie automatyczne jest wyłączone, jedynym językiem, który będzie wyświetlał się w twoim oprogramowaniu, jest język domyślny. Włącz wykrywanie automatyczne, aby rozszerzyć język.',
    },
    manage_language: {
        title: 'Zarządzanie językiem',
        subtitle: 'Lokalizuj doświadczenie użytkownika przez dodawanie języków i tłumaczeń. Twoje tłumaczenie może być ustawione jako domyślny język.',
        add_language: 'Dodaj język',
        logto_provided: 'Logto dostarczony',
        key: 'Klucz',
        logto_source_values: 'Logto źródłowe wartości',
        custom_values: 'Własne wartości',
        clear_all_tip: 'Wyczyść wszystkie wartości',
        unsaved_description: 'Zmiany nie zostaną zapisane, jeśli wyjdziecie z tej strony bez zapisywania.',
        deletion_tip: 'Usuń język',
        deletion_title: 'Czy chcesz usunąć dodany język?',
        deletion_description: 'Po usunięciu użytkownicy nie będą mogli przeglądać w tym języku.',
        default_language_deletion_title: 'Domyślny język nie może zostać usunięty.',
        default_language_deletion_description: '{{language}} jest ustawiony jako twój domyślny język i nie może być usunięty.',
    },
    advanced_options: {
        title: 'OPCJE ZAAWANSOWANE',
        enable_user_registration: 'Włącz rejestrację użytkowników',
        enable_user_registration_description: 'Włącz lub wyłącz rejestrację użytkowników. Po wyłączeniu użytkownicy nadal mogą być dodawani w konsoli administracyjnej, ale nie mogą już zakładać kont za pomocą interfejsu logowania.',
    },
};
export default others;
