const logs = {
    page_title: '审计日志',
    title: '审计日志',
    subtitle: '查看用户行为和事件',
    event: '事件',
    user: '用户',
    application: '应用',
    time: '时间',
    filter_by: '过滤',
};
export default logs;
