const contact = {
    title: 'Kontakt',
    description: 'Tritt unserer Community bei, um Feedback zu geben, um Hilfe zu bitten und deine Gedanken mit anderen Entwicklern zu teilen',
    discord: {
        title: 'Discord channel',
        description: 'Tritt unserem öffentlichen Kanal bei, um mit anderen Entwicklern zu chatten',
        button: 'Beitreten',
    },
    github: {
        title: 'GitHub',
        description: 'Erstelle ein Issue bei GitHub',
        button: 'Öffnen',
    },
    email: {
        title: 'E-Mail senden',
        description: 'Schick uns eine E-Mail für weitere Informationen und Hilfe',
        button: 'Senden',
    },
};
export default contact;
