const contact = {
    title: 'お問い合わせ',
    description: 'フィードバックを提供したり、助けを求めたり、他の開発者との考えを共有するために、当社コミュニティに参加してください。',
    discord: {
        title: 'ディスコードチャンネル',
        description: '他の開発者とチャットするために、パブリックチャンネルに参加してください。',
        button: '参加',
    },
    github: {
        title: 'GitHub',
        description: '問題を作成してGitHubに提出してください。',
        button: '開く',
    },
    email: {
        title: 'メール送信',
        description: '詳細な情報やヘルプを求めるために、私たちにメールを送信してください。',
        button: '送信',
    },
};
export default contact;
