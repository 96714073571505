const contact = {
    title: 'Contate-nos',
    description: 'Junte-se à nossa comunidade para fornecer feedback, pedir ajuda e compartilhar pensamentos com outros desenvolvedores',
    discord: {
        title: 'Discord',
        description: 'Junte-se ao nosso canal público para conversar com outros desenvolvedores',
        button: 'Juntar',
    },
    github: {
        title: 'GitHub',
        description: 'Crie um issue e submeta no GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Enviar email',
        description: 'Envie-nos um email para mais informações e ajuda',
        button: 'Enviar',
    },
};
export default contact;
