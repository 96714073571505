const demo_app = {
    title: 'Pomyślnie zalogowałeś się do podglądu na żywo!',
    subtitle: 'Oto twoje informacje logowania:',
    username: 'Nazwa użytkownika: ',
    user_id: 'ID użytkownika: ',
    sign_out: 'Wyloguj się z podglądu na żywo',
    continue_explore: 'Lub kontynuuj przeglądanie',
    customize_sign_in_experience: 'Dostosuj wrażenia z logowania',
    enable_passwordless: 'Włącz logowanie bez hasła',
    add_social_connector: 'Dodaj złącze społecznościowe',
};
export default demo_app;
