import action from './action.js';
import demo_app from './demo-app.js';
import description from './description.js';
import error from './error.js';
import input from './input.js';
import secondary from './secondary.js';
const ko = Object.freeze({
    translation: {
        input,
        secondary,
        action,
        description,
        error,
        demo_app,
    },
});
export default ko;
