const menu = {
    profile: 'Perfil',
    language: 'Idioma',
    appearance: {
        label: 'Apariencia',
        light: 'Modo Claro',
        dark: 'Modo Oscuro',
        system: 'Sincronizar con el sistema',
    },
    sign_out: 'Cerrar sesión',
};
export default menu;
