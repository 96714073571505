const contact = {
    title: 'Связаться с нами',
    description: 'Присоединяйтесь к нашему сообществу, чтобы оставлять отзывы, задавать вопросы и делиться своими мыслями с другими разработчиками',
    discord: {
        title: 'Канал Discord',
        description: 'Присоединяйтесь к нашему общественному каналу, чтобы общаться с другими разработчиками',
        button: 'Присоединиться',
    },
    github: {
        title: 'GitHub',
        description: 'Создайте проблему и отправьте на GitHub',
        button: 'Открыть',
    },
    email: {
        title: 'Отправить электронное письмо',
        description: 'Отправьте нам электронное письмо для получения дополнительной информации и помощи',
        button: 'Отправить',
    },
};
export default contact;
