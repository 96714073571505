const menu = {
    profile: 'Profile',
    language: 'Langue',
    appearance: {
        label: 'Apparence',
        light: 'Mode clair',
        dark: 'Mode sombre',
        system: 'Synchronisation avec le système',
    },
    sign_out: 'Sign Out',
};
export default menu;
