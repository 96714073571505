const contact = {
    title: 'Contáctanos',
    description: 'Únete a nuestra comunidad para proporcionar comentarios, pedir ayuda y compartir tus ideas con otros desarrolladores',
    discord: {
        title: 'Canal de Discord',
        description: 'Únete a nuestro canal público para chatear con otros desarrolladores',
        button: 'Únete',
    },
    github: {
        title: 'GitHub',
        description: 'Crea un problema y envíalo en GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Enviar correo electrónico',
        description: 'Envíanos un correo electrónico para obtener más información y ayuda',
        button: 'Enviar',
    },
};
export default contact;
