@use '@/scss/underscore' as _;

.content {
  align-items: center;
}

.congrats {
  width: 160px;
  height: 160px;
}

.title {
  margin-top: _.unit(6);
  font: var(--font-title-1);
  text-align: center;
}

.description {
  margin-top: _.unit(3);
  text-align: center;
  font: var(--font-body-2);
}

.form {
  width: 100%;
  margin-top: _.unit(6);

  .cardFieldHeadline {
    margin-bottom: _.unit(2);
  }
}
