@use '@/scss/underscore' as _;

.userName {
  font: var(--body-medium);
  color: var(--color-text);

  .title {
    display: inline-block;
    max-width: 100%;
    @include _.text-ellipsis;
    margin-right: _.unit(1);
  }

  .id {
    display: inline-block;
    font: var(--body-small);
    color: var(--color-text-secondary);
    @include _.text-ellipsis;
  }

  .link {
    display: inline-block;
    text-decoration: none;
    color: var(--color-text-link);
  }
}
