const verification_code = {
    phone_email_empty: 'Telefon ve e-posta alanları boş.',
    not_found: 'Doğrulama kodu bulunamadı. Lütfen önce doğrulama kodu gönderin.',
    phone_mismatch: 'Telefon eşleşmiyor. Lütfen yeni bir doğrulama kodu isteyin.',
    email_mismatch: 'E-posta eşleşmiyor. Lütfen yeni bir doğrulama kodu isteyin.',
    code_mismatch: 'Geçersiz doğrulama kodu.',
    expired: 'Doğrulama kodu süresi dolmuştur. Lütfen yeni bir doğrulama kodu isteyin.',
    exceed_max_try: 'Doğrulama kodu deneme sınırı aşıldı. Lütfen yeni bir doğrulama kodu isteyin.',
};
export default verification_code;
