const roles = {
    page_title: 'Roles',
    title: 'Roles',
    subtitle: 'Роли включают права доступа, которые определяют, что может делать пользователь. RBAC использует роли для предоставления пользователям доступа к ресурсам для конкретных действий.',
    create: 'Создать роль',
    role_name: 'Роль',
    role_description: 'Описание',
    role_name_placeholder: 'Введите название роли',
    role_description_placeholder: 'Введите описание роли',
    assigned_users: 'Назначенные пользователи',
    assign_permissions: 'Назначить права доступа',
    create_role_title: 'Создать роль',
    create_role_description: 'Создание и управление ролями для ваших приложений. Роли содержат коллекции прав доступа и могут быть назначены пользователям.',
    create_role_button: 'Создать роль',
    role_created: 'Роль "{{name}}" успешно создана!',
    search: 'Поиск по названию роли, описанию или ID',
    placeholder_title: 'Роли',
    placeholder_description: 'Роли являются группировкой разрешений, которые могут быть назначены пользователям. Необходимо добавить разрешения, прежде чем создать роли.',
};
export default roles;
