const users = {
    page_title: 'Gerenciamento de usuários',
    title: 'Gerenciamento de usuários',
    subtitle: 'Gerenciar identidades de usuários, visualização de logs de usuários, redefinições de senha e exclusão de usuários',
    create: 'Adicionar usuário',
    create_subtitle: 'Fornecer pelo menos um dos seguintes campos para prosseguir.',
    error_missing_identifier: 'Você deve fornecer pelo menos um identificador para criar um usuário.',
    user_name: 'Nome de usuário',
    application_name: 'Aplicativo',
    latest_sign_in: 'Último login',
    create_form_username: 'Nome de usuário',
    create_form_password: 'Senha',
    create_form_name: 'Nome completo',
    placeholder_email: 'seuemail@dominio.com',
    placeholder_username: 'Seu nome de usuário',
    placeholder_phone: '+55 11 1234-5678',
    unnamed: 'Sem nome',
    search: 'Busca por nome, e-mail, telefone ou nome de usuário',
    check_user_detail: 'Detalhes do usuário',
    placeholder_title: 'Gerenciamento de usuários',
    placeholder_description: 'Cada usuário possui um perfil contendo todas as informações do usuário. Ele é composto por dados básicos, identidades sociais e dados personalizados.',
};
export default users;
