const dashboard = {
    page_title: '儀表板',
    title: '儀表板',
    description: '查看所有應用的數據概況',
    total_users: '總用戶',
    total_users_tip: '總用戶',
    new_users_today: '今日新增',
    new_users_today_tip: '今日註冊到你應用上的新用戶數',
    new_users_7_days: '7 日新增',
    new_users_7_days_tip: '最近 7 日註冊到你應用上的新用戶數',
    daily_active_users: '日活用戶',
    daily_active_users_tip: '今日在你的應用上交換過 token 的獨立用戶數',
    weekly_active_users: '周活用戶',
    weekly_active_users_tip: '最近 7 日在你的應用上交換過 token 的獨立用戶數',
    monthly_active_users: '月活用戶',
    monthly_active_users_tip: '最近 30 日在你的應用上交換過 token 的獨立用戶數',
};
export default dashboard;
