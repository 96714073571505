const others = {
    terms_of_use: {
        title: 'KULLANIM KOŞULLARI',
        terms_of_use: 'Kullanım koşulları URLi',
        terms_of_use_placeholder: 'https://kullanım.koşulları/',
        privacy_policy: 'Gizlilik politikası URLi',
        privacy_policy_placeholder: 'https://gizlilik.politikası/',
    },
    languages: {
        title: 'DİLLER',
        enable_auto_detect: 'Otomatik algılamayı etkinleştir',
        description: 'Yazılımınız kullanıcının yerel ayarını algılar ve yerel dile geçer. İngilizceden diğer bir dile UI çevirerek yeni diller ekleyebilirsiniz.',
        manage_language: 'Dili yönetin',
        default_language: 'Varsayılan dil',
        default_language_description_auto: 'Algılanan kullanıcı diline karşı geçerli diller kitaplığında listelenmeyen dil durumunda varsayılan dil kullanılır.',
        default_language_description_fixed: 'Otomatik algılamayı kapattığınızda varsayılan dil yazılımınızın göstereceği tek dil olacaktır. Dil uzantısı için otomatik algılamayı açın.',
    },
    manage_language: {
        title: 'Dili Yönetin',
        subtitle: 'Dilleri ve çevirileri ekleyerek ürün deneyimini yerelleştirin. Katkınız varsayılan dil olarak ayarlanabilir.',
        add_language: 'Dil ekle',
        logto_provided: 'Logto sağlandı',
        key: 'Anahtar',
        logto_source_values: 'Logto kaynak değerleri',
        custom_values: 'Özel değerler',
        clear_all_tip: 'Tüm değerleri temizle',
        unsaved_description: 'Sayfadan kaydedilmeyen değişiklikler kaybolabilir.',
        deletion_title: 'Eklenen dili silmek istediğinizden emin misiniz?',
        deletion_tip: 'Dili sil',
        deletion_description: 'Silmeden sonra, kullanıcılar artık o dille tarama yapamazlar.',
        default_language_deletion_title: 'Varsayılan dil silinemez.',
        default_language_deletion_description: '{{language}} varsayılan dil olarak ayarlanmıştır ve silinemez.',
    },
    advanced_options: {
        title: 'GELİŞMİŞ OPSİYONLAR',
        enable_user_registration: 'Kullanıcı kaydını etkinleştir',
        enable_user_registration_description: 'Kullanıcı kaydını etkinleştirin veya devre dışı bırakın. Devre dışı bırakıldıktan sonra, kullanıcılar yönetici konsolunda eklenebilir, ancak kullanıcılar artık oturum açma arayüzü üzerinden hesap oluşturamazlar.',
    },
};
export default others;
