const menu = {
    profile: 'Profil',
    language: 'Sprache',
    appearance: {
        label: 'Darstellung',
        light: 'Hell',
        dark: 'Dunkel',
        system: 'Synchonisiere mit Systemeinstellungen',
    },
    sign_out: 'Abmelden',
};
export default menu;
