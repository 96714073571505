const general = {
    placeholder: 'Заполнитель',
    skip: 'Пропустить',
    next: 'Следующий',
    back: 'Назад',
    retry: 'Попробуйте снова',
    done: 'Готово',
    search: 'Поиск',
    search_placeholder: 'Поиск',
    clear_result: 'Очистить результаты',
    save: 'Сохранить',
    save_changes: 'Сохранить изменения',
    saved: 'Сохранено!',
    discard: 'Отказаться',
    loading: 'Загрузка...',
    redirecting: 'Перенаправление...',
    add: 'Добавить',
    added: 'Добавлено',
    cancel: 'Отмена',
    confirm: 'Подтверждение',
    check_out: 'Оформить',
    create: 'Создать',
    set_up: 'Настройка',
    customize: 'Настроить',
    enable: 'Включить',
    reminder: 'Напоминание',
    delete: 'Удалить',
    more_options: 'ДОПОЛНИТЕЛЬНЫЕ ОПЦИИ',
    close: 'Закрыть',
    copy: 'Копировать',
    copying: 'Копирование',
    copied: 'Скопировано',
    required: 'Обязательное',
    add_another: 'Добавить еще',
    deletion_confirmation: 'Вы точно хотите удалить этот {{title}}?',
    settings_nav: 'Настройки',
    unsaved_changes_warning: 'Вы внесли изменения. Вы точно хотите покинуть эту страницу?',
    leave_page: 'Покинуть страницу',
    stay_on_page: 'Остаться на странице',
    type_to_search: 'Поиск по названию',
    got_it: 'Понятно',
    continue: 'Продолжить',
    page_info: '{{min, number}}-{{max, number}} из {{total, number}}',
    learn_more: 'Узнать больше',
    tab_errors: '{{count, number}} ошибок',
    skip_for_now: 'Пропустить',
    remove: 'Удалить',
    visit: 'Перейти',
    join: 'Присоединиться',
    try_now: 'Попробуйте сейчас',
    multiple_form_field: '(Несколько)',
    demo: 'Демо',
    unnamed: 'Без имени',
};
export default general;
