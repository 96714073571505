@use '@/scss/underscore' as _;

.field {
  &:not(:first-child) {
    margin-top: _.unit(6);
  }
}

.headline {
  display: flex;
  align-items: center;
  margin-bottom: _.unit(1);

  .title {
    font: var(--font-label-2);
    color: var(--color-text);

    .multiple {
      margin-left: _.unit(1);
      font: var(--font-body-2);
      color: var(--color-text-secondary);
    }
  }

  .toggleTipButton {
    margin-left: _.unit(0.5);
  }

  .required {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }
}
