const verification_code = {
    phone_email_empty: "Les deux le téléphone et l'email sont vides.",
    not_found: "Le code de vérification n'a pas été trouvé. Veuillez d'abord envoyer le code de vérification.",
    phone_mismatch: 'Téléphone ne correspond pas. Veuillez demander un nouveau code de vérification.',
    email_mismatch: 'Email ne correspond pas. Veuillez demander un nouveau code de vérification.',
    code_mismatch: 'Code de vérification invalide.',
    expired: 'Le code de vérification a expiré. Veuillez demander un nouveau code de vérification.',
    exceed_max_try: 'La limite de tentatives de code de vérification a été dépassée. Veuillez demander un nouveau code de vérification.',
};
export default verification_code;
