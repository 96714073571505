import { LogtoTenantConfigKey } from '../types/index.js';
export const createDefaultAdminConsoleConfig = (forTenantId) => Object.freeze({
    tenantId: forTenantId,
    key: LogtoTenantConfigKey.AdminConsole,
    value: {
        livePreviewChecked: false,
        applicationCreated: false,
        signInExperienceCustomized: false,
        passwordlessConfigured: false,
        furtherReadingsChecked: false,
        roleCreated: false,
        communityChecked: false,
        m2mApplicationCreated: false,
    },
});
