const log_details = {
    page_title: 'Denetim Kaydı Detayları',
    back_to_logs: 'Denetim Kayıtlarına Geri Dön',
    back_to_user: "{{name}}'in Kayıtlarına Geri Dön",
    success: 'Başarılı',
    failed: 'Başarısız',
    event_key: 'Olay Anahtarı',
    application: 'Uygulama',
    ip_address: 'IP Adresi',
    user: 'Kullanıcı',
    log_id: 'Kayıt Kimliği',
    time: 'Süre',
    user_agent: 'Kullanıcı Aracısı',
    tab_details: 'Detaylar',
    raw_data: 'Ham Veri',
};
export default log_details;
