// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    interactionJti: z.string().max(128).nullable().optional(),
    phone: z.string().max(32).nullable().optional(),
    email: z.string().max(128).nullable().optional(),
    type: z.string().min(1).max(32),
    code: z.string().min(1).max(6),
    consumed: z.boolean().optional(),
    tryCount: z.number().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    interactionJti: z.string().max(128).nullable(),
    phone: z.string().max(32).nullable(),
    email: z.string().max(128).nullable(),
    type: z.string().max(32),
    code: z.string().max(6),
    consumed: z.boolean(),
    tryCount: z.number(),
    createdAt: z.number(),
});
export const Passcodes = Object.freeze({
    table: 'passcodes',
    tableSingular: 'passcode',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        interactionJti: 'interaction_jti',
        phone: 'phone',
        email: 'email',
        type: 'type',
        code: 'code',
        consumed: 'consumed',
        tryCount: 'try_count',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'interactionJti',
        'phone',
        'email',
        'type',
        'code',
        'consumed',
        'tryCount',
        'createdAt',
    ],
    createGuard,
    guard,
});
