@use '@/scss/underscore' as _;

.draggleItemContainer {
  transform: translate(0, 0);
}

.signInMethodItem {
  display: flex;
  align-items: center;
  margin: _.unit(2) 0;
}

.signInMethod {
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  margin-right: _.unit(2);
  padding: _.unit(3) _.unit(2);
  background-color: var(--color-layer-2);
  border-radius: 8px;
  cursor: move;
  color: var(--color-text);

  &.error {
    outline: 1px solid var(--color-error);
  }

  .identifier {
    width: 130px;
    display: flex;
    font: var(--font-label-2);
    align-items: center;
  }

  .authentication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 _.unit(2);
    flex-grow: 1;

    .checkBox {
      flex-grow: 1;
      width: 100%;
    }

    .swapButton {
      margin: 0 _.unit(4);
    }

    &.verifyCodePrimary {
      flex-direction: row-reverse;
    }
  }

  .draggableIcon {
    color: var(--color-text-secondary);
    margin-right: _.unit(1);
  }
}

.plusIcon {
  color: var(--color-text-secondary);
}

.addAnotherSignInMethodDropdown {
  min-width: 208px;
}

.addSignInMethodDropDown {
  min-width: unset;
}

.errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
}
