@use '@/scss/underscore' as _;

.alert {
  padding: _.unit(3) _.unit(4);
  margin: _.unit(2) 0;
  font: var(--font-body-2);
  display: flex;
  border-radius: 8px;
  align-items: center;

  &.shadow {
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-1);
  }

  .icon {
    width: 20px;
    height: 20px;
    color: var(--color-text-secondary);
  }

  .content {
    flex: 1;
    margin: 0 _.unit(3);
  }

  &.info {
    background: var(--color-surface-variant);

    .icon {
      color: var(--color-neutral-variant-60);
    }
  }
}
