const users = {
    page_title: 'Kullanıcı Yönetimi',
    title: 'Kullanıcı Yönetimi',
    subtitle: 'Kullanıcı oluşturma, kullanıcı bilgilerini düzenleme, kullanıcı kayıtlarını görüntüleme, parola sıfırlama ve kullanıcıları silme dahil olmak üzere kullanıcı kimliklerini yönetin',
    create: 'Kullanıcı ekle',
    create_subtitle: 'Devam etmek için en az bir alanı sağlayın.',
    error_missing_identifier: 'Bir kullanıcı oluşturmak için en az bir tanımlayıcı sağlamanız gerekir.',
    user_name: 'Kullanıcı',
    application_name: 'Uygulamadan',
    latest_sign_in: 'En son oturum açma',
    create_form_username: 'Kullanıcı Adı',
    create_form_password: 'Şifre',
    create_form_name: 'Ad Soyad',
    placeholder_email: 'youremail@domain.com',
    placeholder_username: 'Kullanıcı adınız',
    placeholder_phone: '+1 555-123-4567',
    unnamed: 'İsimsiz',
    search: 'İsim, email, telefon veya kullanıcı adına göre arama',
    check_user_detail: 'Kullanıcı detaylarını kontrol et',
    placeholder_title: 'Kullanıcı yönetimi',
    placeholder_description: 'Her kullanıcının temel verileri, sosyal kimlikleri ve özel verilerini içeren bir profil vardır.',
};
export default users;
