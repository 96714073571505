const demo_app = {
    title: '你已成功登录实时预览！',
    subtitle: '以下是本次登录的用户信息：',
    username: '用户名：',
    user_id: '用户 ID：',
    sign_out: '退出实时预览',
    continue_explore: '或继续探索',
    customize_sign_in_experience: '自定义登录体验',
    enable_passwordless: '启用无密码登录',
    add_social_connector: '添加社交连接器',
};
export default demo_app;
