const get_started = {
    page_title: 'Начало работы',
    progress: 'Руководство: {{completed}}/{{total}}',
    progress_dropdown_title: 'Несколько вещей, которые вы можете сделать ...',
    title: 'Что-то, что поможет вам добиться успеха',
    subtitle_part1: 'Несколько вещей, которые вы можете сделать, чтобы быстро получить ценность от Logto',
    subtitle_part2: 'Я профессионал и прошел все шаги.',
    hide_this: 'Скрыть это',
    confirm_message: 'Вы уверены, что хотите скрыть эту страницу? Это действие нельзя отменить.',
    check_preview_title: 'Проверьте живой просмотр',
    check_preview_subtitle: 'Попробуйте опыт входа в систему Logto сейчас, чтобы увидеть, как это работает',
    integration_title: 'Создание и интеграция приложения',
    integration_subtitle: 'Настройте аутентификацию Logto для вашего нативного, одностраничного, машинного или традиционного приложения',
    custom_sie_title: 'Настройка входа в систему',
    custom_sie_subtitle: 'Разблокируйте огромный диапазон сценариев с помощью расширенных настроек',
    passwordless_title: 'Масштабирование входа без пароля, добавляя свои коннекторы',
    passwordless_subtitle: 'Попробуйте войти без пароля и включите безопасный и безтрудный опыт для своих клиентов',
    community_title: 'Присоединяйтесь к нашему сообществу в Discord',
    community_subtitle: 'Присоединяйтесь к нашему общедоступному каналу, чтобы общаться с другими разработчиками',
    management_api_title: 'Взаимодействие с API управления',
    management_api_subtitle: 'Прямое подключение вашей системы аутентификации к нашему API управления',
    further_readings_title: 'Дополнительные чтения',
    further_readings_subtitle: 'Ознакомьтесь со справочными материалами пошагово и сценарийным способом без утомительных концепций',
    add_rbac_title: 'Добавить контроль доступа на основе ролей, чтобы защитить свои ресурсы',
    add_rbac_subtitle: 'Управляйте своими ресурсами через масштабируемую авторизацию ролей для разнообразных случаев использования.',
};
export default get_started;
