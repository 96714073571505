const menu = {
    profile: 'Profil',
    language: 'Język',
    appearance: {
        label: 'Wygląd',
        light: 'Tryb jasny',
        dark: 'Tryb ciemny',
        system: 'Synchronizuj z systemem',
    },
    sign_out: 'Wyloguj się',
};
export default menu;
