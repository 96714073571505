const get_started = {
    page_title: 'Rozpocznij',
    progress: 'Przewodnik rozpoczęcia: {{completed}}/{{total}}',
    progress_dropdown_title: 'Kilka rzeczy, które możesz zrobić...',
    title: 'Coś do odkrycia, aby pomóc ci odnieść sukces',
    subtitle_part1: 'Kilka rzeczy, które możesz zrobić, aby szybko osiągnąć wartość Logto',
    subtitle_part2: 'Jestem ekspertem i ukończyłem wszystkie etapy.',
    hide_this: 'Ukryj to',
    confirm_message: 'Jesteś pewny, że chcesz ukryć tę stronę? Tej czynności nie można cofnąć.',
    check_preview_title: 'Sprawdź podgląd na żywo',
    check_preview_subtitle: 'Wypróbuj teraz doświadczenie Logto przy logowaniu, aby zobaczyć, jak działa',
    integration_title: 'Utwórz i zintegruj swoją aplikację',
    integration_subtitle: 'Skonfiguruj uwierzytelnianie Logto dla swojej aplikacji natywnej, jednostronicowej, maszynowej lub tradycyjnej',
    custom_sie_title: 'Dostosuj doświadczenie logowania',
    custom_sie_subtitle: 'Odblokuj szeroki zakres scenariuszy za pomocą zaawansowanych ustawień',
    passwordless_title: 'Skaluj logowanie bez hasła, dodając własne łączniki',
    passwordless_subtitle: 'Spróbuj logowania bez hasła i zapewnij swoim klientom bezpieczne i bezproblemowe doświadczenie',
    community_title: 'Dołącz do naszej społeczności Discord',
    community_subtitle: 'Dołącz do naszego publicznego kanału, aby porozmawiać z innymi deweloperami',
    management_api_title: 'Interakcja z API zarządzania',
    management_api_subtitle: 'Bezpośrednio połącz swój system uwierzytelniający z naszym API zarządzania',
    further_readings_title: 'Dalsza lektura',
    further_readings_subtitle: 'Sprawdź nasze krok po kroku, oparte na scenariuszach dokumenty bez nużących pojęć',
    add_rbac_title: 'Dodaj kontrolę dostępu opartą na rolach, aby chronić swoje zasoby',
    add_rbac_subtitle: 'Kontroluj swoje zasoby poprzez skalowalną autoryzację ról dla różnorodnych przypadków użycia.',
};
export default get_started;
