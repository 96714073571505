const permissions = {
    search_placeholder: 'API veya izin adına göre ara',
    search_placeholder_without_api: 'İzin adına göre ara',
    name_column: 'İzin',
    description_column: 'Açıklama',
    api_column: 'API',
    placeholder_title: 'İzin',
    placeholder_description: 'İzin, bir kaynağa erişmek için yetki verme durumunu ifade eder (biz buna API kaynağı diyoruz).',
};
export default permissions;
