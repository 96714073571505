const contatto = {
    title: 'Contattaci',
    description: 'Unisciti alla nostra comunità per fornire feedback, chiedere aiuto e condividere le tue idee con altri sviluppatori',
    discord: {
        title: 'Canale Discord',
        description: 'Unisciti al nostro canale pubblico per chattare con altri sviluppatori',
        button: 'Unisciti',
    },
    github: {
        title: 'GitHub',
        description: 'Crea un problema e invialo su GitHub',
        button: 'Apri',
    },
    email: {
        title: 'Invia email',
        description: 'Invia una email per ulteriori informazioni e supporto',
        button: 'Invia',
    },
};
export default contatto;
