const api_resources = {
    page_title: 'Ресурсы API',
    title: 'Ресурсы API',
    subtitle: 'Определите API, которые могут использовать ваши авторизованные приложения',
    create: 'Создать ресурс API',
    api_name: 'Название API',
    api_name_placeholder: 'Введите название вашего API',
    api_identifier: 'Идентификатор API',
    api_identifier_tip: 'Уникальный идентификатор для ресурса API. Он должен быть абсолютным URI и не иметь фрагмента (#). Равен параметру <a>resource</a> в OAuth 2.0.',
    api_resource_created: 'Ресурс API {{name}} был успешно создан',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
export default api_resources;
