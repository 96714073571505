const dashboard = {
    page_title: 'Dashboard',
    title: 'Dashboard',
    description: 'Ottieni una panoramica sulle prestazioni della tua app',
    total_users: 'Totale utenti',
    total_users_tip: 'Totale utenti',
    new_users_today: 'Nuovi utenti oggi',
    new_users_today_tip: 'Il numero di nuovi utenti registrati sulle tue app oggi',
    new_users_7_days: 'Nuovi utenti negli ultimi 7 giorni',
    new_users_7_days_tip: 'Il numero di nuovi utenti registrati sulle tue app negli ultimi 7 giorni',
    daily_active_users: 'Utenti attivi giornalieri',
    daily_active_users_tip: 'Il numero di utenti unici che hanno scambiato token sulle tue app oggi',
    weekly_active_users: 'Utenti attivi settimanali',
    weekly_active_users_tip: 'Il numero di utenti unici che hanno scambiato token sulle tue app negli ultimi 7 giorni',
    monthly_active_users: 'Utenti attivi mensili',
    monthly_active_users_tip: 'Il numero di utenti unici che hanno scambiato token sulle tue app negli ultimi 30 giorni',
};
export default dashboard;
