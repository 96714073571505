const log_details = {
    page_title: 'Detalle del registro de auditoría',
    back_to_logs: 'Volver a los registros de auditoría',
    back_to_user: 'Volver a {{name}}',
    success: 'Éxito',
    failed: 'Fallido',
    event_key: 'Clave del evento',
    application: 'Aplicación',
    ip_address: 'Dirección IP',
    user: 'Usuario',
    log_id: 'ID de registro',
    time: 'Tiempo',
    user_agent: 'Agente de usuario',
    tab_details: 'Detalles',
    raw_data: 'Datos sin procesar',
};
export default log_details;
