const dashboard = {
    page_title: 'Dashboard',
    title: 'Dashboard',
    description: 'Obtén una vista general sobre el rendimiento de tu aplicación',
    total_users: 'Usuarios totales',
    total_users_tip: 'Usuarios totales',
    new_users_today: 'Nuevos usuarios hoy',
    new_users_today_tip: 'Número de nuevos usuarios registrados en tu aplicación hoy',
    new_users_7_days: 'Nuevos usuarios últimos 7 días',
    new_users_7_days_tip: 'Número de nuevos usuarios registrados en tu aplicación en los últimos 7 días',
    daily_active_users: 'Usuarios activos diarios',
    daily_active_users_tip: 'Número de usuarios únicos que intercambiaron tokens en tu aplicación hoy',
    weekly_active_users: 'Usuarios activos semanales',
    weekly_active_users_tip: 'Número de usuarios únicos que intercambiaron tokens en tu aplicación en los últimos 7 días',
    monthly_active_users: 'Usuarios activos mensuales',
    monthly_active_users_tip: 'Número de usuarios únicos que intercambiaron tokens en tu aplicación en los últimos 30 días',
};
export default dashboard;
