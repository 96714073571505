const get_started = {
    page_title: '開始上手',
    progress: '開始上手: {{completed}}/{{total}}',
    progress_dropdown_title: '一些快速上手的操作',
    title: '成功開發身份方案，我們先來探索一番',
    subtitle_part1: '下列是一些可以快速上手的操作，通過這些，你可以更好地感受 Logto 的價值',
    subtitle_part2: '我已經完成了這些設置。',
    hide_this: '隱藏引導',
    confirm_message: '你確認要隱藏該頁面嗎? 本操作將無法恢復。',
    check_preview_title: '查看實時預覽',
    check_preview_subtitle: '來體驗 Logto 登錄吧',
    integration_title: '創建和集成你的應用程序',
    integration_subtitle: '為你的本地、單頁、機器對機器或傳統應用程序設置 Logto 身份驗證',
    custom_sie_title: '自定義登錄體驗',
    custom_sie_subtitle: '使用高級設置解鎖各種場景',
    passwordless_title: '通過添加自己的連接器來擴展無密碼登錄',
    passwordless_subtitle: '嘗試無密碼登錄，並為你的客戶提供安全和無摩擦的體驗',
    community_title: '加入我們的 Discord 社區',
    community_subtitle: '加入我們的公共頻道與其他開發人員交流',
    management_api_title: '與管理 API 交互',
    management_api_subtitle: '直接將你的身份驗證系統連接到我們的管理 API',
    further_readings_title: '進一步閱讀',
    further_readings_subtitle: '查看我們的逐步、基於場景的文檔，避免繁瑣的概念',
    add_rbac_title: '添加基於角色的訪問控制以保護你的資源',
    add_rbac_subtitle: '通過可擴展的角色授權控制你的資源，以適應各種用例。',
};
export default get_started;
