const permissions = {
    search_placeholder: 'Поиск по имени API или разрешению',
    search_placeholder_without_api: 'Поиск по наименованию разрешения',
    name_column: 'Разрешение',
    description_column: 'Описание',
    api_column: 'API',
    placeholder_title: 'Разрешение',
    placeholder_description: 'Разрешение относится к авторизации доступа к ресурсу (мы называем это ресурсом API).',
};
export default permissions;
