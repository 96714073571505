const general = {
    placeholder: '占位符',
    skip: '跳过',
    next: '下一步',
    back: '上一步',
    retry: '重试',
    done: '完成',
    search: '搜索',
    search_placeholder: '搜索',
    clear_result: '清除结果',
    save: '保存',
    save_changes: '保存更改',
    saved: '保存成功!',
    discard: '放弃',
    loading: '读取中...',
    redirecting: '页面跳转中...',
    add: '添加',
    added: '已添加',
    cancel: '取消',
    confirm: '确认',
    check_out: '查看',
    create: '创建',
    set_up: '配置',
    customize: '自定义',
    enable: '启用',
    reminder: '提示',
    delete: '删除',
    more_options: '更多选项',
    close: '关闭',
    copy: '复制',
    copying: '复制中',
    copied: '已复制',
    required: '必填',
    add_another: '新增',
    deletion_confirmation: '你确定要删除这个 {{title}} 吗?',
    settings_nav: '设置',
    unsaved_changes_warning: '还有未保存的变更, 确定要离开吗？',
    leave_page: '离开此页',
    stay_on_page: '留在此页',
    type_to_search: '输入搜索',
    got_it: '知道了',
    continue: '继续',
    page_info: '{{min, number}}-{{max, number}} 共 {{total, number}} 条',
    learn_more: '了解更多',
    tab_errors: '{{count, number}} 个错误',
    skip_for_now: '先跳过',
    remove: '移除',
    visit: '访问',
    join: '加入',
    try_now: '现在试试',
    multiple_form_field: '（多选）',
    demo: '演示',
    unnamed: '未命名',
};
export default general;
