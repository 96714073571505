const logs = {
    page_title: 'Dziennik audytu',
    title: 'Dziennik audytu',
    subtitle: 'Wyświetl dane dziennika dotyczące zdarzeń uwierzytelnienia dokonywanych przez Twoich użytkowników',
    event: 'Zdarzenie',
    user: 'Użytkownik',
    application: 'Aplikacja',
    time: 'Czas',
    filter_by: 'Filtruj według',
};
export default logs;
