@use '@/scss/underscore' as _;

.container {
  border-radius: 16px;
  background-color: var(--color-neutral-variant-90);
  padding: _.unit(6);
  display: flex;
  flex-direction: column;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
