// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    userId: z.string().min(1).max(21),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    userId: z.string().max(21),
    createdAt: z.number(),
});
export const VerificationStatuses = Object.freeze({
    table: 'verification_statuses',
    tableSingular: 'verification_status',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        userId: 'user_id',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'userId',
        'createdAt',
    ],
    createGuard,
    guard,
});
