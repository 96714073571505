const tabs = {
    get_started: 'はじめに',
    dashboard: 'ダッシュボード',
    applications: 'アプリケーション',
    api_resources: 'API リソース',
    sign_in_experience: 'サインイン',
    connectors: 'コネクタ',
    webhooks: 'Webhooks',
    users: 'ユーザー管理',
    audit_logs: '監査ログ',
    roles: '役割',
    docs: 'ドキュメント',
    contact_us: 'お問い合わせ',
    settings: '設定',
};
export default tabs;
