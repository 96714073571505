const contact = {
    title: '聯繫我們',
    description: '加入我們的社群，在這裡您可以提供產品建議、尋求幫助或和其他開發者交流心得',
    discord: {
        title: 'Discord 頻道',
        description: '加入我們的公共頻道，和其他開發者一起交流使用經驗',
        button: '立即加入',
    },
    github: {
        title: 'GitHub',
        description: '透過 GitHub 給我們提一個 issue',
        button: '前往頁面',
    },
    email: {
        title: '郵件聯繫',
        description: '透過郵件聯繫獲取資訊或尋求幫助',
        button: '發送郵件',
    },
};
export default contact;
