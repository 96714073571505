const roles = {
    page_title: 'Rollen',
    title: 'Rollen',
    subtitle: 'Rollen beinhalten Berechtigungen, die bestimmen, was ein Benutzer tun kann. RBAC verwendet Rollen, um Benutzern Zugriff auf Ressourcen für bestimmte Aktionen zu geben.',
    create: 'Rolle erstellen',
    role_name: 'Rolle',
    role_description: 'Beschreibung',
    role_name_placeholder: 'Geben Sie Ihren Rollennamen ein',
    role_description_placeholder: 'Geben Sie Ihre Rollenbeschreibung ein',
    assigned_users: 'Zugeordnete Benutzer',
    assign_permissions: 'Berechtigungen zuweisen',
    create_role_title: 'Rolle erstellen',
    create_role_description: 'Erstellen und verwalten Sie Rollen für Ihre Anwendungen. Rollen enthalten Sammlungen von Berechtigungen und können Benutzern zugewiesen werden.',
    create_role_button: 'Rolle erstellen',
    role_created: 'Die Rolle {{name}} wurde erfolgreich erstellt!',
    search: 'Nach Rollennamen, Beschreibung oder ID suchen',
    placeholder_title: 'Rollen',
    placeholder_description: 'Rollen sind eine Gruppierung von Berechtigungen, die Benutzern zugewiesen werden können. Stellen Sie sicher, dass Sie zuerst Berechtigungen hinzufügen, bevor Sie Rollen erstellen.',
};
export default roles;
