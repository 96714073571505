const applications = {
    page_title: 'Applications',
    title: 'Applications',
    subtitle: "Configurez une application mobile, une page unique, machine to machine ou une application traditionnelle pour utiliser Logto pour l'authentification.",
    create: 'Créer une application',
    application_name: "Nom de l'application",
    application_name_placeholder: 'Mon app',
    application_description: "Description de l'application",
    application_description_placeholder: 'Entrer la description de votre application',
    select_application_type: "Sélectionner un type d'application",
    no_application_type_selected: "Vous n'avez pas encore sélectionné de type d'application",
    application_created: "L'application {{name}} a été créée avec succès ! \nMaintenant, terminez les paramètres de votre application",
    app_id: 'App ID',
    type: {
        native: {
            title: 'Application native',
            subtitle: 'Une application qui fonctionne dans un environnement natif',
            description: 'Exemple: application iOS, application Android',
        },
        spa: {
            title: 'Application à page unique',
            subtitle: "Une application qui s'exécute dans un navigateur web et met dynamiquement à jour les données sur place.",
            description: 'Exemple: application React, application Vue',
        },
        traditional: {
            title: 'Web traditionnel',
            subtitle: 'Une application qui met à jour les pages par le seul serveur web.',
            description: 'Exemple: Next.js, PHP',
        },
        machine_to_machine: {
            title: 'Machine-to-Machine',
            subtitle: 'Une application (généralement un service) qui communique directement avec les ressources',
            description: 'Par exemple, un service backend',
        },
    },
    guide: {
        get_sample_file: 'Obtenir un exemple',
        header_description: 'Suivez un guide étape par étape pour intégrer votre application ou cliquez sur le bouton de droite pour obtenir notre exemple de projet.',
        title: "L'application a été créée avec succès",
        subtitle: 'Suivez maintenant les étapes ci-dessous pour terminer la configuration de votre application. Veuillez sélectionner le type de SDK pour continuer.',
        description_by_sdk: "Ce guide de démarrage rapide montre comment intégrer Logto dans l'application {{sdk}}.",
    },
    placeholder_title: "Sélectionnez un type d'application pour continuer",
    placeholder_description: "Logto utilise une entité d'application pour OIDC pour aider aux tâches telles que l'identification de vos applications, la gestion de la connexion et la création de journaux d'audit.",
};
export default applications;
