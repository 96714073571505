const log_details = {
    page_title: '审计日志详情',
    back_to_logs: '返回审计日志',
    back_to_user: '返回 {{name}}',
    success: '成功',
    failed: '失败',
    event_key: '事件 Key',
    application: '应用',
    ip_address: 'IP 地址',
    user: '用户',
    log_id: '日志 ID',
    time: '时间',
    user_agent: '用户代理',
    tab_details: '详情',
    raw_data: '原始数据',
};
export default log_details;
