const dashboard = {
    page_title: 'Dashboard',
    title: 'Dashboard',
    description: 'Verschaffe dir einen Überblick über die Leistung deiner App',
    total_users: 'Gesamtzahl der Benutzer',
    total_users_tip: 'Gesamtzahl der Benutzer',
    new_users_today: 'Neue Benutzer heute',
    new_users_today_tip: 'Neue Benutzer, die sich heute in deinen Anwendungen registriert haben',
    new_users_7_days: 'Neue Benutzer in den letzten 7 Tagen',
    new_users_7_days_tip: 'Neue Benutzer, die sich in den letzten 7 Tagen in deinen Anwendungen registriert haben',
    daily_active_users: 'Täglich aktive Benutzer',
    daily_active_users_tip: 'Die Anzahl der einzelnen Benutzer, die heute Token in deinen Anwendungen ausgetauscht haben',
    weekly_active_users: 'Wöchentlich aktive Benutzer',
    weekly_active_users_tip: 'Die Anzahl der einzelnen Benutzer, die in den letzten 7 Tagen Token in deinen Anwendungen ausgetauscht haben',
    monthly_active_users: 'Monatlich aktive Benutzer',
    monthly_active_users_tip: 'Die Anzahl der einzelnen Benutzer, die in den letzten 30 Tagen Token in deinen Anwendungen ausgetauscht haben',
};
export default dashboard;
