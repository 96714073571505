const log_details = {
    page_title: '監査ログの詳細',
    back_to_logs: '監査ログに戻る',
    back_to_user: '{{name}}に戻る',
    success: '成功',
    failed: '失敗',
    event_key: 'イベントキー',
    application: 'アプリケーション',
    ip_address: 'IPアドレス',
    user: 'ユーザー',
    log_id: 'ログID',
    time: '時間',
    user_agent: 'ユーザーエージェント',
    tab_details: '詳細',
    raw_data: '生データ',
};
export default log_details;
