const api_resources = {
    page_title: 'Recursos da API',
    title: 'Recursos da API',
    subtitle: 'Defina APIs que você pode consumir de seus aplicativos autorizados',
    create: 'Criar recurso de API',
    api_name: 'Nome da API',
    api_name_placeholder: 'Digite o nome da sua API',
    api_identifier: 'Identificador de API',
    api_identifier_tip: 'O identificador exclusivo para o recurso da API. Deve ser um URI absoluto e não tem nenhum componente de fragmento (#). Igual ao <a>parâmetro de recurso</a> em OAuth 2.0.',
    api_resource_created: 'O recurso API {{name}} foi criado com sucesso',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
export default api_resources;
