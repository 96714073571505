const log_details = {
    page_title: 'Detalhes do registo de auditoria',
    back_to_logs: 'Voltar aos registos de auditoria',
    back_to_user: 'Voltar para {{name}}',
    success: 'Sucesso',
    failed: 'Falha',
    event_key: 'Chave do Evento',
    application: 'Aplicação',
    ip_address: 'Endereço IP',
    user: 'Utilizador',
    log_id: 'ID do registo',
    time: 'Hora',
    user_agent: 'User agent',
    tab_details: 'Detalhes',
    raw_data: 'Dados não tratados',
};
export default log_details;
