@use '@/scss/underscore' as _;

.topbar {
  flex: 0 0 64px;
  width: 100%;
  padding: 0 _.unit(6);
  display: flex;
  align-items: center;

  .logo {
    width: auto;
    height: 28px;
    color: var(--color-text);
  }

  .line {
    @include _.vertical-bar;
    height: 20px;
    margin: 0 _.unit(5);
  }

  .text {
    font: var(--font-title-2);
    color: var(--color-text);
  }
}
