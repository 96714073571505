const get_started = {
    page_title: 'Começar',
    progress: 'Guia de primeiros passos: {{completed}}/{{total}}',
    progress_dropdown_title: 'Algumas coisas que pode fazer...',
    title: 'Algo para explorar para o ajudar a ter sucesso',
    subtitle_part1: 'Algumas coisas que pode fazer para obter rapidamente o valor do Logto',
    subtitle_part2: 'Acabei com esta configuração. ',
    hide_this: 'Ocultar isto',
    confirm_message: 'Tem a certeza que deseja ocultar esta página? Esta ação não pode ser desfeita.',
    check_preview_title: 'Ver a pré-visualização ao vivo',
    check_preview_subtitle: 'Experimente a experiência de login do Logto agora para ver como funciona',
    integration_title: 'Crie e integre a sua aplicação',
    integration_subtitle: 'Configure a autenticação do Logto para a sua aplicação nativa, single page, machine to machine ou tradicional',
    custom_sie_title: 'Personalize a experiência de login',
    custom_sie_subtitle: 'Desbloqueie uma vasta gama de cenários com definições avançadas',
    passwordless_title: 'Aumente a escalabilidade do login sem senha adicionando os seus próprios conectores',
    passwordless_subtitle: 'Experimente o login sem senha e ative uma experiência segura e sem fricção para os seus clientes',
    community_title: 'Junte-se à nossa comunidade no Discord',
    community_subtitle: 'Junte-se ao nosso canal público para conversar com outros programadores',
    management_api_title: 'Interaja com a Management API',
    management_api_subtitle: 'Ligue diretamente o seu sistema de autenticação à nossa Management API',
    further_readings_title: 'Leituras adicionais',
    further_readings_subtitle: 'Confira os nossos documentos passo a passo e baseados em cenários, sem conceitos tediosos',
    add_rbac_title: 'Adicione controlo de acesso baseado em funções para proteger seus recursos',
    add_rbac_subtitle: 'Controle seus recursos através de uma autorização de funções escalável para diversos casos de uso.',
};
export default get_started;
