@use '@/scss/underscore' as _;

.imageUploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  padding: _.unit(3.75) _.unit(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .delete {
    display: none;
    position: absolute;
    right: _.unit(2);
    bottom: _.unit(2);
  }

  > img {
    height: 40px;
    max-width: 100%;
    object-fit: contain;
    cursor: not-allowed;
  }

  &:hover {
    .delete {
      display: block;
    }
  }
}
