const roles = {
    page_title: 'Ruoli',
    title: 'Ruoli',
    subtitle: "I ruoli includono le autorizzazioni che determinano ciò che un utente può fare. RBAC utilizza i ruoli per dare agli utenti l'accesso alle risorse necessarie per specifiche azioni.",
    create: 'Crea Ruolo',
    role_name: 'Ruolo',
    role_description: 'Descrizione',
    role_name_placeholder: 'Inserisci il nome del tuo ruolo',
    role_description_placeholder: 'Inserisci la descrizione del tuo ruolo',
    assigned_users: 'Utenti assegnati',
    assign_permissions: 'Assegna autorizzazioni',
    create_role_title: 'Crea Ruolo',
    create_role_description: 'Crea e gestisci ruoli per le tue applicazioni. I ruoli contengono collezioni di autorizzazioni e possono essere assegnati agli utenti.',
    create_role_button: 'Crea Ruolo',
    role_created: 'Il ruolo {{name}} è stato creato con successo!',
    search: 'Cerca per nome, descrizione o ID del ruolo',
    placeholder_title: 'Ruoli',
    placeholder_description: 'I ruoli sono un raggruppamento di autorizzazioni che possono essere assegnati agli utenti. Assicurati di aggiungere le autorizzazioni prima di creare i ruoli.',
};
export default roles;
