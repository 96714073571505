const tabs = {
    get_started: 'Iniziare',
    dashboard: 'Dashboard',
    applications: 'Applicazioni',
    api_resources: 'Risorse API',
    sign_in_experience: 'Esperienza di accesso',
    connectors: 'Connettori',
    webhooks: 'Webhooks',
    users: 'Gestione utenti',
    audit_logs: 'Registri di verifica',
    roles: 'Ruoli',
    docs: 'Documenti',
    contact_us: 'Contattaci',
    settings: 'Impostazioni',
};
export default tabs;
