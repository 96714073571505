const tabs = {
    get_started: 'Erste Schritte',
    dashboard: 'Dashboard',
    applications: 'Anwendungen',
    api_resources: 'API Ressourcen',
    sign_in_experience: 'Anmeldeoberfläche',
    connectors: 'Connectoren',
    webhooks: 'Webhooks',
    users: 'Benutzerverwaltung',
    audit_logs: 'Audit Logs',
    roles: 'Rollen',
    docs: 'Dokumentation',
    contact_us: 'Kontakt',
    settings: 'Einstellungen',
};
export default tabs;
