@use '@/scss/underscore' as _;

.users {
  display: flex;
  align-items: center;

  .avatars {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .avatar {
      position: relative;
      border: 2px solid var(--color-layer-1);
      box-sizing: content-box;

      &:not(:last-child) {
        margin-left: _.unit(-2);
      }
    }
  }

  .count {
    margin-left: _.unit(2);
    color: var(--color-text);
    font: var(--font-body-2);
  }
}

.empty {
  padding-left: _.unit(0.5);
}
