@use '@/scss/underscore' as _;

.broadcast {
  background-color: var(--color-neutral-variant-90);
  color: var(--color-neutral-variant-30);
  position: absolute;
  top: _.unit(4);
  left: 50%;
  font: var(--font-label-2);
  border-radius: _.unit(4);
  padding: _.unit(1.5) _.unit(4);
  user-select: none;
  text-align: center;
  transform: translateX(-50%);

  .link {
    margin-left: _.unit(1);
  }
}
