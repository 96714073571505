const logs = {
    page_title: "Journaux d'audit",
    title: "Journaux d'audit",
    subtitle: "Visualisez les données du journal des événements d'authentification effectués par votre administrateur et vos utilisateurs.",
    event: 'Événement',
    user: 'Utilisateur',
    application: 'Application',
    time: 'Temps',
    filter_by: 'Filtrer par',
};
export default logs;
