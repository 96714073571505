const verification_code = {
    phone_email_empty: '手機號碼與電子郵件地址均為空',
    not_found: '驗證碼不存在，請先請求發送驗證碼',
    phone_mismatch: '手機號碼不匹配，請嘗試請求新的驗證碼。',
    email_mismatch: '電子郵件地址不匹配，請嘗試請求新的驗證碼。',
    code_mismatch: '驗證碼不正確',
    expired: '驗證碼已過期，請嘗試請求新的驗證碼。',
    exceed_max_try: '超過最大驗證次數，請嘗試請求新的驗證碼。',
};
export default verification_code;
