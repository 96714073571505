const contact = {
    title: '联系我们',
    description: '加入我们的社区，在这里你可以给我们提供产品建议，寻求帮助或和其他开发者交流心得',
    discord: {
        title: 'Discord 频道',
        description: '加入我们的公共频道，和其他开发者一起交流使用经验',
        button: '立即加入',
    },
    github: {
        title: 'GitHub',
        description: '通过 GitHub 给我们提一个 issue',
        button: '前往页面',
    },
    email: {
        title: '邮件联系',
        description: '通过邮件联系获取信息或寻求帮助',
        button: '发送邮件',
    },
};
export default contact;
