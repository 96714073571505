// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { oidcClientMetadataGuard, customClientMetadataGuard } from './../foundations/index.js';
import { ApplicationType } from './custom-types.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    name: z.string().min(1).max(256),
    secret: z.string().min(1).max(64),
    description: z.string().nullable().optional(),
    type: z.nativeEnum(ApplicationType),
    oidcClientMetadata: oidcClientMetadataGuard,
    customClientMetadata: customClientMetadataGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    name: z.string().max(256),
    secret: z.string().max(64),
    description: z.string().nullable(),
    type: z.nativeEnum(ApplicationType),
    oidcClientMetadata: oidcClientMetadataGuard,
    customClientMetadata: customClientMetadataGuard,
    createdAt: z.number(),
});
export const Applications = Object.freeze({
    table: 'applications',
    tableSingular: 'application',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        name: 'name',
        secret: 'secret',
        description: 'description',
        type: 'type',
        oidcClientMetadata: 'oidc_client_metadata',
        customClientMetadata: 'custom_client_metadata',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'name',
        'secret',
        'description',
        'type',
        'oidcClientMetadata',
        'customClientMetadata',
        'createdAt',
    ],
    createGuard,
    guard,
});
