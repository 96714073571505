@use '@/scss/underscore' as _;

.inspire {
  margin-top: _.unit(3);
  display: flex;
  padding: _.unit(4) _.unit(5);
  border-radius: 12px;
  background-color: var(--color-base);
  align-items: center;
  justify-content: space-between;

  .inspireContent {
    margin-right: _.unit(6);
    display: flex;
    flex-direction: column;

    .inspireTitle {
      font: var(--font-title-2);
      margin-bottom: _.unit(1);
    }

    .inspireDescription {
      font: var(--font-body-2);
    }
  }

  .button {
    border-color: var(--color-neutral-variant-80);
    // Note: this is a special case for the inspire me button since the bulb icon does not follow the standard icon size
    padding-right: _.unit(7);

    &:not(:disabled) {
      &:not(:active),
      &:active {
        &:hover {
          background: var(--color-layer-1) center / 90% no-repeat url('../../../../assets/images/fireworks.png');
        }
      }

      &:active:hover {
        background-color: transparent;
      }
    }
  }
}
