const get_started = {
    page_title: 'はじめに',
    progress: 'はじめにガイド：{{completed}}/{{total}}',
    progress_dropdown_title: 'できることは何かあるかな？',
    title: 'あなたの成功を手助けする探索',
    subtitle_part1: 'すばやくLogtoの価値を得るには、いくつかのことができます',
    subtitle_part2: '私はプロであり、すべてのステップを完了しました。 ',
    hide_this: 'これを隠す',
    confirm_message: 'このページを隠すことを確認しますか？この操作は取り消せません。',
    check_preview_title: 'ライブプレビューを確認してください',
    check_preview_subtitle: 'Logtoのサインインエクスペリエンスを今すぐ試して、どのように機能するかを確認してください',
    integration_title: 'アプリケーションの作成と統合',
    integration_subtitle: 'ネイティブ、シングルページ、機械間、または従来のアプリケーションにLogto認証を設定してください',
    custom_sie_title: 'サインインエクスペリエンスをカスタマイズする',
    custom_sie_subtitle: '高度な設定で、さまざまなシナリオを解除する',
    passwordless_title: 'コネクタを追加して、パスワードなしのサインインをスケーリングする',
    passwordless_subtitle: 'パスワードなしでサインインを試し、お客様に安全で摩擦の少ないエクスペリエンスを提供してください。',
    community_title: 'ディスコードコミュニティに参加する',
    community_subtitle: '他の開発者とチャットするためのパブリックチャンネルに参加してください。',
    management_api_title: 'Management APIとのやり取り',
    management_api_subtitle: '認証システムを直接管理APIに接続してください。',
    further_readings_title: 'さらなる読書',
    further_readings_subtitle: '面倒な概念なしで、ステップバイステップ、シナリオベースのドキュメントをチェックしてください。',
    add_rbac_title: 'ロールベースのアクセス制御を追加して、リソースを保護する',
    add_rbac_subtitle: '多様なユースケースに対応するスケーラブルなロール承認により、リソースを制御します。',
};
export default get_started;
