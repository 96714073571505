const contact = {
    title: 'Skontaktuj się z nami',
    description: 'Dołącz do naszej społeczności, żeby dać opinię, poprosić o pomoc i podzielić się swoimi myślami z innymi developerami',
    discord: {
        title: 'Kanał Discord',
        description: 'Dołącz do naszego publicznego kanału, żeby rozmawiać z innymi developerami',
        button: 'Dołącz',
    },
    github: {
        title: 'GitHub',
        description: 'Utwórz problem i wyślij na GitHub',
        button: 'Otwórz',
    },
    email: {
        title: 'Wyślij e-mail',
        description: 'Wyślij nam e-maila dla dalszych informacji i pomocy',
        button: 'Wyślij',
    },
};
export default contact;
