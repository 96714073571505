const permissions = {
    search_placeholder: 'API 또는 권한 이름으로 검색',
    search_placeholder_without_api: '권한 이름으로 검색',
    name_column: '권한',
    description_column: '설명',
    api_column: 'API',
    placeholder_title: '권한',
    placeholder_description: '권한은 리소스(API 리소스라고 함)에 액세스할 수 있는 권한을 의미해요.',
};
export default permissions;
