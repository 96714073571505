const permissions = {
    search_placeholder: 'Cerca per nome API o permesso',
    search_placeholder_without_api: 'Cerca per nome permesso',
    name_column: 'Permesso',
    description_column: 'Descrizione',
    api_column: 'API',
    placeholder_title: 'Permesso',
    placeholder_description: "Il permesso si riferisce all'autorizzazione per accedere ad una risorsa (la chiamiamo risorsa API).",
};
export default permissions;
