@use '@/scss/underscore' as _;

.targetScopeItem {
  display: flex;
  align-items: center;
  padding: _.unit(1.5) _.unit(4);

  .title {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    font: var(--font-body-2);
    overflow: hidden;

    .name {
      flex-shrink: 0;
      max-width: 204px;
      padding: _.unit(1) _.unit(2);
      border-radius: 6px;
      background: var(--color-neutral-95);
      @include _.text-ellipsis;
    }

    .resourceName {
      margin: 0 _.unit(2);
      color: var(--color-text-secondary);
      @include _.text-ellipsis;
    }
  }

  .icon {
    color: var(--color-text-secondary);
  }

  &:hover {
    background: var(--color-hover);
  }
}
