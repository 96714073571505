const api_resources = {
    page_title: 'Zasoby API',
    title: 'Zasoby API',
    subtitle: 'Definiuj API, z których korzystać mogą twoje autoryzowane aplikacje',
    create: 'Utwórz zasób API',
    api_name: 'Nazwa API',
    api_name_placeholder: 'Wprowadź nazwę swojego API',
    api_identifier: 'Identyfikator API',
    api_identifier_tip: 'Unikalny identyfikator zasobu API. Musi to być bezwzględny adres URI bez składnika fragmentu (#). Jest równy <a>parametrowi zasobu</a> w standardzie OAuth 2.0.',
    api_resource_created: 'Zasób API {{name}} został pomyślnie utworzony',
    api_identifier_placeholder: 'https://identyfikator-twojego-api/',
};
export default api_resources;
