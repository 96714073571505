const log_details = {
    page_title: 'Dettagli registro di verifica',
    back_to_logs: 'Torna ai log di verifica',
    back_to_user: 'Torna a {{name}}',
    success: 'Successo',
    failed: 'Fallito',
    event_key: 'Chiave evento',
    application: 'Applicazione',
    ip_address: 'Indirizzo IP',
    user: 'Utente',
    log_id: 'ID registro',
    time: 'Tempo',
    user_agent: 'User agent',
    tab_details: 'Dettagli',
    raw_data: 'Dati grezzi',
};
export default log_details;
