@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.body {
  .connectorGroup {
    gap: _.unit(4);
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: dim.$modal-layout-grid-large) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: dim.$modal-layout-grid-medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: dim.$modal-layout-grid-small) {
      grid-template-columns: repeat(1, 1fr);
    }

    &.medium {
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: dim.$modal-layout-grid-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &.large {
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: dim.$modal-layout-grid-medium) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: dim.$modal-layout-grid-small) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .connector {
      font: var(--font-body-2);
      display: flex;

      .content {
        flex: 1;
        margin-left: _.unit(3);

        .name {
          font: var(--font-label-2);
          @include _.multi-line-ellipsis(1);
          padding-right: _.unit(3);
        }

        .connectorId {
          margin-top: _.unit(1);
          font: var(--font-body-3);
          color: var(--color-text-secondary);
        }

        .description {
          font: var(--font-body-3);
          color: var(--color-text-secondary);
          margin-top: _.unit(1);
          @include _.multi-line-ellipsis(4);
        }
      }
    }
  }
}
