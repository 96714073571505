const dashboard = {
    page_title: 'Panel',
    title: 'Panel',
    description: 'Przegląd wydajności Twojej aplikacji',
    total_users: 'Wszyscy użytkownicy',
    total_users_tip: 'Wszyscy użytkownicy',
    new_users_today: 'Nowi użytkownicy dzisiaj',
    new_users_today_tip: 'Liczba nowych użytkowników zarejestrowanych dzisiaj',
    new_users_7_days: 'Nowi użytkownicy w ciągu ostatnich 7 dni',
    new_users_7_days_tip: 'Liczba nowych użytkowników zarejestrowanych w ciągu ostatnich 7 dni',
    daily_active_users: 'Dziennie aktywni użytkownicy',
    daily_active_users_tip: 'Liczba unikalnych użytkowników wymieniających tokeny na Twojej aplikacji dziś',
    weekly_active_users: 'Tygodniowo aktywni użytkownicy',
    weekly_active_users_tip: 'Liczba unikalnych użytkowników wymieniających tokeny na Twojej aplikacji w ciągu ostatnich 7 dni',
    monthly_active_users: 'Miesięcznie aktywni użytkownicy',
    monthly_active_users_tip: 'Liczba unikalnych użytkowników wymieniających tokeny na Twojej aplikacji w ciągu ostatnich 30 dni',
};
export default dashboard;
