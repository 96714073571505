const action = {
    sign_in: 'Giriş Yap',
    continue: 'İlerle',
    create_account: 'Hesap Oluştur',
    create_account_without_linking: 'Bağlamadan hesap oluştur',
    create: 'Oluştur',
    enter_passcode: 'Doğrulama kodunu girin',
    confirm: 'Onayla',
    cancel: 'İptal Et',
    save_password: 'Şifreyi kaydet',
    bind: '{{address}} ile birleştir',
    bind_and_continue: 'Bağla ve devam et',
    back: 'Geri Dön',
    nav_back: 'Geri',
    agree: 'Kabul Et',
    got_it: 'Anladım',
    sign_in_with: '{{name}} ile ilerle',
    forgot_password: 'Şifremi Unuttum?',
    switch_to: '{{method}} yöntemine geç',
    sign_in_via_passcode: 'Doğrulama koduyla oturum açın',
    sign_in_via_password: 'şifre ile giriş yap',
    change: '{{method}} değiştir',
    link_another_email: 'Başka bir e-postayı bağla',
    link_another_phone: 'Başka bir telefon bağla',
    link_another_email_or_phone: 'Başka bir e-posta veya telefon bağlayın',
    show_password: 'Şifreyi göster',
};
export default action;
