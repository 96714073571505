// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { translationGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    languageTag: z.string().min(1).max(16),
    translation: translationGuard,
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    languageTag: z.string().max(16),
    translation: translationGuard,
});
export const CustomPhrases = Object.freeze({
    table: 'custom_phrases',
    tableSingular: 'custom_phrase',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        languageTag: 'language_tag',
        translation: 'translation',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'languageTag',
        'translation',
    ],
    createGuard,
    guard,
});
