const general = {
    placeholder: '占位符',
    skip: '跳過',
    next: '下一步',
    back: '上一步',
    retry: '重試',
    done: '完成',
    search: '搜索',
    search_placeholder: '搜索',
    clear_result: '清除結果',
    save: '儲存',
    save_changes: '儲存更改',
    saved: '儲存成功!',
    discard: '放棄',
    loading: '讀取中...',
    redirecting: '頁面跳轉中...',
    add: '新增',
    added: '已新增',
    cancel: '取消',
    confirm: '確認',
    check_out: '查看',
    create: '建立',
    set_up: '設置',
    customize: '自定義',
    enable: '啟用',
    reminder: '提示',
    delete: '刪除',
    more_options: '更多選項',
    close: '關閉',
    copy: '複製',
    copying: '複製中',
    copied: '已複製',
    required: '必填',
    add_another: '新增',
    deletion_confirmation: '你確定要刪除這個 {{title}} 嗎?',
    settings_nav: '設置',
    unsaved_changes_warning: '還有未儲存的變更, 確定要離開嗎？',
    leave_page: '離開此頁',
    stay_on_page: '留在此頁',
    type_to_search: '輸入搜尋',
    got_it: '知道了',
    continue: '繼續',
    page_info: '{{min, number}}-{{max, number}} 共 {{total, number}} 條',
    learn_more: '了解更多',
    tab_errors: '{{count, number}} 個錯誤',
    skip_for_now: '先跳過',
    remove: '移除',
    visit: '訪問',
    join: '加入',
    try_now: '現在試試',
    multiple_form_field: '（多選）',
    demo: '演示',
    unnamed: '未命名',
};
export default general;
