const verification_code = {
    phone_email_empty: 'Tanto el teléfono como el correo electrónico están vacíos.',
    not_found: 'No se encontró el código de verificación. Por favor enviar primero el código de verificación.',
    phone_mismatch: 'Teléfono no coincide. Por favor solicite un nuevo código de verificación.',
    email_mismatch: 'Correo electrónico no coincide. Por favor solicite un nuevo código de verificación.',
    code_mismatch: 'Código de verificación inválido.',
    expired: 'El código de verificación ha caducado. Por favor solicite un nuevo código de verificación.',
    exceed_max_try: 'Se ha superado el límite de intentos de verificación de código. Por favor solicite un nuevo código de verificación.',
};
export default verification_code;
