const user_details = {
    page_title: 'Детали пользователя',
    back_to_users: 'Вернуться к управлению пользователями',
    created_title: 'Этот пользователь был успешно создан',
    created_guide: 'Вот информация, которая поможет пользователю в процессе входа в систему.',
    created_email: 'Адрес электронной почты:',
    created_phone: 'Номер телефона:',
    created_username: 'Имя пользователя:',
    created_password: 'Пароль:',
    menu_delete: 'Удалить',
    delete_description: 'Это действие нельзя отменить. Оно окончательно удалит пользователя.',
    deleted: 'Пользователь был успешно удален',
    reset_password: {
        reset_password: 'Сбросить пароль',
        title: 'Вы уверены, что хотите сбросить пароль?',
        content: 'Это действие нельзя отменить. Это сбросит информацию для входа пользователя.',
        congratulations: 'Пользователь был сброшен',
        new_password: 'Новый пароль:',
    },
    tab_settings: 'Настройки',
    tab_roles: 'Роли',
    tab_logs: 'Журналы пользователя',
    settings: 'Настройки',
    settings_description: 'У каждого пользователя есть профиль, содержащий всю информацию о пользователе. Он состоит из основных данных, социальных идентификаторов и пользовательских данных.',
    field_email: 'Адрес электронной почты',
    field_phone: 'Номер телефона',
    field_username: 'Имя пользователя',
    field_name: 'Имя',
    field_avatar: 'URL изображения аватара',
    field_avatar_placeholder: 'https://your.cdn.domain/avatar.png',
    field_custom_data: 'Пользовательские данные',
    field_custom_data_tip: 'Дополнительная информация о пользователе, не указанная в заранее определенных свойствах пользователя, таких как предпочтительный цвет и язык пользователя.',
    field_connectors: 'Социальные подключения',
    custom_data_invalid: 'Пользовательские данные должны быть допустимым JSON-объектом',
    connectors: {
        connectors: 'Подключения',
        user_id: 'ID пользователя',
        remove: 'Удалить',
        not_connected: 'Пользователь не подключен к социальным подключениям',
        deletion_confirmation: 'Вы удаляете существующий идентификатор <name/>. Вы уверены, что хотите это сделать?',
    },
    suspended: 'Приостановлен',
    suspend_user: 'Приостановить пользователя',
    suspend_user_reminder: 'Вы уверены, что хотите приостановить этого пользователя? Пользователь не сможет войти в ваше приложение, и он не сможет получить новый токен доступа после истечения срока действия текущего токена. Кроме того, любые API-запросы, сделанные этим пользователем, завершатся неудачей.',
    suspend_action: 'Приостановить',
    user_suspended: 'Пользователь был приостановлен.',
    reactivate_user: 'Возобновить пользователя',
    reactivate_user_reminder: 'Вы уверены, что хотите возобновить этого пользователя? Это позволит любые попытки входа в систему для этого пользователя.',
    reactivate_action: 'Возобновить',
    user_reactivated: 'Пользователь был возобновлен.',
    roles: {
        name_column: 'Роль',
        description_column: 'Описание',
        assign_button: 'Назначить роли',
        delete_description: 'Это действие удалит эту роль у данного пользователя. Роль все еще будет существовать, но она больше не будет связана с этим пользователем.',
        deleted: '{{name}} был успешно удален из этого пользователя.',
        assign_title: 'Назначить роли {{name}}',
        assign_subtitle: 'Авторизовать {{name}} в одной или нескольких ролях',
        assign_role_field: 'Назначить роли',
        role_search_placeholder: 'Поиск по названию роли',
        added_text: '{{value, number}} добавлено',
        assigned_user_count: '{{value, number}} пользователей',
        confirm_assign: 'Назначить роли',
        role_assigned: 'Роль(и) успешно назначена(ы)',
        search: 'Поиск по названию роли, описанию или ID',
        empty: 'Нет доступных ролей',
    },
};
export default user_details;
