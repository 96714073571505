import type { SVGProps } from 'react';

function Contact(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8346 3.33594H4.16797C3.50493 3.33594 2.86904 3.59933 2.4002 4.06817C1.93136 4.53701 1.66797 5.1729 1.66797 5.83594V14.1693C1.66797 14.8323 1.93136 15.4682 2.4002 15.937C2.86904 16.4059 3.50493 16.6693 4.16797 16.6693H15.8346C16.4977 16.6693 17.1336 16.4059 17.6024 15.937C18.0712 15.4682 18.3346 14.8323 18.3346 14.1693V5.83594C18.3346 5.1729 18.0712 4.53701 17.6024 4.06817C17.1336 3.59933 16.4977 3.33594 15.8346 3.33594ZM15.493 5.0026L10.593 9.9026C10.5155 9.98071 10.4233 10.0427 10.3218 10.085C10.2202 10.1273 10.1113 10.1491 10.0013 10.1491C9.89129 10.1491 9.78237 10.1273 9.68082 10.085C9.57927 10.0427 9.4871 9.98071 9.40964 9.9026L4.50964 5.0026H15.493ZM16.668 14.1693C16.668 14.3903 16.5802 14.6022 16.4239 14.7585C16.2676 14.9148 16.0556 15.0026 15.8346 15.0026H4.16797C3.94696 15.0026 3.73499 14.9148 3.57871 14.7585C3.42243 14.6022 3.33464 14.3903 3.33464 14.1693V6.1776L8.23464 11.0776C8.70339 11.5458 9.3388 11.8087 10.0013 11.8087C10.6638 11.8087 11.2992 11.5458 11.768 11.0776L16.668 6.1776V14.1693Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Contact;
