const dashboard = {
    page_title: 'Painel',
    title: 'Painel',
    description: 'Obtenha uma visão geral sobre o desempenho da sua aplicação',
    total_users: 'Utilizadores totais',
    total_users_tip: 'Nº de Utilizadores registados',
    new_users_today: 'Novos utilizadores hoje',
    new_users_today_tip: 'O número de novos utilizadores registados nas suas aplicações hoje',
    new_users_7_days: 'Novos utilizadores nos últimos 7 dias',
    new_users_7_days_tip: 'O número de novos utilizadores registados nas suas aplicações nos últimos sete dias',
    daily_active_users: 'Utilizadores ativos diariamente',
    daily_active_users_tip: 'O número de utilizadores únicos que efetuaram login nas suas aplicações hoje',
    weekly_active_users: 'Utilizadores ativos semanalmente',
    weekly_active_users_tip: 'O número de utilizadores únicos que efetuaram login nas suas aplicações nos últimos sete dias',
    monthly_active_users: 'Utilizadores ativos mensais',
    monthly_active_users_tip: 'O número de utilizadores únicos que efetuaram login nas suas aplicações nos últimos 30 dias',
};
export default dashboard;
