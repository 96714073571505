const connector_details = {
    page_title: 'Bağdaştırıcı ayrıntıları',
    back_to_connectors: 'Bağdaştırıcılara geri dön',
    check_readme: 'READMEye bak',
    settings: 'Genel ayarlar',
    settings_description: "Bağdaştırıcılar Logto'da kritik bir rol oynar. Onların yardımıyla, Logto son kullanıcıların şifresiz kayıt veya giriş yapmasını ve sosyal hesaplarla giriş yapabilme özelliklerini sağlar.",
    parameter_configuration: 'Parametre yapılandırması',
    test_connection: 'Bağlantıyı test et',
    save_error_empty_config: 'Lütfen yapılandırmayı girin',
    send: 'Gönder',
    send_error_invalid_format: 'Geçersiz giriş',
    edit_config_label: 'Json girin',
    test_email_sender: 'Eposta bağdaştırıcınızı test edin',
    test_sms_sender: 'SMS bağdaştırıcınızı test edin',
    test_email_placeholder: 'john.doe@example.com',
    test_sms_placeholder: '+90 555 123 45 67',
    test_message_sent: 'Test mesajı gönderildi',
    test_sender_description: 'Logto testler için "Generic" şablonunu kullanır. Bağlantınız doğru şekilde yapılandırılmışsa bir mesaj alacaksınız.',
    options_change_email: 'Eposta bağdaştırıcınızı değiştirin',
    options_change_sms: 'SMS bağdaştırıcınızı değiştirin',
    connector_deleted: 'Bağdaştırıcı başarıyla silindi',
    type_email: 'Eposta bağdaştırıcısı',
    type_sms: 'SMS bağdaştırıcısı',
    type_social: 'Sosyal bağdaştırıcı',
    in_used_social_deletion_description: 'Bu bağdaştırıcı, giriş deneyiminizde kullanımda. Silerek, <name/> giriş deneyiminiz, giriş deneyimi ayarlarında silinecektir. Geri eklemeye karar verirseniz yeniden yapılandırmanız gerekecektir.',
    in_used_passwordless_deletion_description: 'Bu {{name}} giriş deneyiminizde kullanımda. Silerek, giriş deneyiminiz çözülünceye kadar doğru çalışmayacaktır. Geri eklemeye karar verirseniz yeniden yapılandırmanız gerekecektir.',
    deletion_description: 'Bu bağdaştırıcıyı kaldırıyorsunuz. Geri alınamaz ve geri eklemeye karar verirseniz yeniden yapılandırmanız gerekecektir.',
};
export default connector_details;
