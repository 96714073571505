// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { logContextPayloadGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    key: z.string().min(1).max(128),
    payload: logContextPayloadGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    key: z.string().max(128),
    payload: logContextPayloadGuard,
    createdAt: z.number(),
});
export const Logs = Object.freeze({
    table: 'logs',
    tableSingular: 'log',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        key: 'key',
        payload: 'payload',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'key',
        'payload',
        'createdAt',
    ],
    createGuard,
    guard,
});
