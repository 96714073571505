const tabs = {
    get_started: 'Rozpocznij',
    dashboard: 'Panel',
    applications: 'Aplikacje',
    api_resources: 'Zasoby API',
    sign_in_experience: 'Doświadczenie przy logowaniu',
    connectors: 'Konnektory',
    webhooks: 'Webhooki',
    users: 'Zarządzanie użytkownikami',
    audit_logs: 'Dzienniki audytu',
    roles: 'Role',
    docs: 'Dokumentacja',
    contact_us: 'Skontaktuj się z nami',
    settings: 'Ustawienia',
};
export default tabs;
